import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService, RolesService } from '../../../auth/services';
import { ContractService } from '../../../contract/services';
import { OfferService } from '../../../offer/services/offer.service';
import { OfferStatus } from '../../enums';

@Component({
  selector: 'app-main',
  templateUrl: './main.view.html',
  styleUrls: ['./main.view.scss'],
})
export class MainViewComponent implements OnInit {
  /** Whether the user has an accepted contract or not. This determines what menu options the user sees. */
  public hasAcceptedContract = false;

  /** Menu options to show for regular users when they have no accepted draft. */
  public offerOnlyOptions = [
    { requiredRole: 'contract::read', location: '/offers', translationKey: 'core.menu.offers' },
  ];

  public userMenuOptions: { translationKey: string; location: string; requiredRole: string }[] = [
    { requiredRole: 'pod::availability::read', location: '/availability', translationKey: 'core.menu.availability' },
    { requiredRole: 'contract::read', location: '/contracts', translationKey: 'core.menu.contract' },
    { requiredRole: 'pod::read', location: '/portfolio', translationKey: 'core.menu.portfolio' },
    { requiredRole: 'powerbi::read', location: '/powerbi', translationKey: 'core.menu.power-bi' },
    { requiredRole: 'contract::read', location: '/offers', translationKey: 'core.menu.offers' },
  ];

  public operatorMenuOptions: { translationKey: string; location: string; requiredRole: string }[] = [
    { requiredRole: 'contract::read', location: '/back-office/contracts', translationKey: 'core.menu.contract' },
    { requiredRole: 'contract::read', location: '/back-office/offers', translationKey: 'core.menu.offers' },
    { requiredRole: 'pod::read', location: '/back-office/portfolio', translationKey: 'core.menu.portfolio' },
    {
      requiredRole: 'management::operator',
      location: '/back-office/users',
      translationKey: 'core.menu.users',
    },
  ];

  /** Returns the menu items what should be rendered based on auth status and roles. */
  get menuOptions(): { translationKey: string; location: string; requiredRole: string }[] {
    const isOperator = this.rolesService.isOperatorInstant();

    if (!this.authService.authenticated) {
      return [];
    }

    if (!isOperator && !this.hasAcceptedContract) {
      return this.offerOnlyOptions;
    }

    return isOperator ? this.operatorMenuOptions : this.userMenuOptions;
  }

  constructor(
    public readonly authService: AuthService,
    public readonly rolesService: RolesService,
    private readonly contractService: ContractService
  ) {
    this.authService.startTokenRefreshCycle();
  }

  public ngOnInit() {
    if (this.authService.authenticated) {
      this.contractService
        .listContracts({ limit: 1 })
        .pipe(
          // If we cannot load offers (due to an error we show all menu options)
          catchError(() => of({ payload: [{}] })),
          map(res => (this.hasAcceptedContract = !!res.payload.length))
        )
        .subscribe();
    } else {
      this.hasAcceptedContract = false;
    }
  }

  logout() {
    this.authService.logout(false);
  }
}
