import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService, RolesService } from './auth/services';

@Injectable({
  providedIn: 'root',
})
export class RefreshRolesInterceptor implements HttpInterceptor {
  private rolesService: RolesService;
  private authService: AuthService;

  constructor(private readonly injector: Injector) {
    /** Need to wait for the translate module to load to avoid circular dependency. */
    setTimeout(() => {
      this.rolesService = injector.get(RolesService);
      this.authService = injector.get(AuthService);
    }, 0);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(async (error: HttpErrorResponse) => {
        /**
         * When the user is not authorized to do something and authenticated we try
         * to refresh the roles cache on the client to be able to disable unauthorized
         * action (buttons) appropriately.
         */
        if (error.status === 403 && this.authService.authenticated) {
          this.rolesService.refresh();
        }

        throw error;
      })
    );
  }
}
