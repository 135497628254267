import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NetworkService } from '@spreadmonitor/network';
import { differenceInMilliseconds } from 'date-fns';
import { jwtDecode } from 'jwt-decode';
import { EMPTY, Observable, timer } from 'rxjs';
import { catchError, filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../shared/interfaces';
import { AuthToken, PasswordLoginResponse } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly TOKEN_NAME = '@alteo/sinergy/auth/token';

  /**
   * Cached user object from the auth token. This value is cached to prevent
   * re-parsing the token every time user us requested.
   */
  private cachedParsedToken: AuthToken | undefined;

  constructor(
    private readonly http: NetworkService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    /** If a token is present at startup then parse it immediately and cache it's value. */
    if (!!localStorage.getItem(this.TOKEN_NAME)) {
      this.setTokenWithUser(this.token);
    }
  }

  get authenticated(): boolean {
    return this.cachedParsedToken && this.isTokenUnexpired;
  }

  get isTokenUnexpired(): boolean {
    return this.cachedParsedToken?.exp > Date.now() / 1000;
  }

  get token(): string {
    return localStorage.getItem(this.TOKEN_NAME);
  }

  get user(): AuthToken | undefined {
    return this.cachedParsedToken;
  }

  /**
   * Logs in a user using email and password.
   */
  public loginUser(email: string, password: string): Observable<PasswordLoginResponse> {
    return this.http
      .post<ApiResponse<PasswordLoginResponse>>('/v2/auth/password-sign-in', { email, password })
      .pipe(pluck('payload'));
  }

  /**
   * Attempts to login with the received token. It checks whether the received
   * or the current token is the "better" (expires later) and saves the best one.
   */
  public async loginWithToken(receivedToken: string): Promise<boolean> {
    const existingToken = localStorage.getItem(this.TOKEN_NAME);
    const now = Date.now() / 1000;
    const receivedTokenInfo = jwtDecode<{ exp: number }>(receivedToken);
    const existingTokenInfo = existingToken ? jwtDecode<{ exp: number }>(existingToken) : null;

    const existingTokenValid = existingTokenInfo?.exp > now ? true : false;
    const receivedTokenValid = receivedTokenInfo?.exp > now ? true : false;

    /**
     * As first step if our current token is expired we remove it.
     * Note that this doesn't effect the current call, as our variables are already set.
     */
    if (!existingTokenValid) {
      this.clearTokenWithUser();
    }

    /** If there's no existing token or the existing token expired and the received token is valid. */
    if ((!existingToken || !existingTokenValid) && receivedTokenValid) {
      this.setTokenWithUser(receivedToken);
    }

    /** If there's an existing valid token, but the received token is valid for a longer period than the one the user already has. */
    if (existingTokenValid && receivedTokenInfo?.exp > existingTokenInfo?.exp) {
      this.setTokenWithUser(receivedToken);
    }

    /** Returns with the success status of the login attempt */
    return this.authenticated;
  }

  /**
   * Logs out the user and redirect the app to auth screen.
   *
   * @param tokenExpired when true a warning will be displayed on login about expired session
   */
  public logout(tokenExpired: boolean = false) {
    this.clearTokenWithUser();

    if (tokenExpired) {
      this.router.navigateByUrl(`/auth/sign-in?token_expired=1`);
    } else {
      this.router.navigateByUrl(`/auth/sign-in`);
    }
  }

  public renewToken(token: string) {
    return this.http.post<ApiResponse<{ token: string }>>('/v2/auth/renew-token', { token }).pipe(pluck('payload'));
  }

  public startTokenRefreshCycle() {
    timer(0, environment.tokenExpirationCheckInterval)
      .pipe(
        filter(() => !!this.token),
        filter(() => {
          const { exp } = jwtDecode<{ exp: number }>(this.token);
          const now = new Date();
          const expiresAt = new Date(exp * 1000);

          return differenceInMilliseconds(expiresAt, now) < environment.tokenExpirationThreshold;
        }),
        map(() => localStorage.getItem(this.TOKEN_NAME)),
        switchMap(token =>
          this.renewToken(token).pipe(
            // This is needed to ensure that if the renew request fails, the observable does not
            // stop with an error and keeps trying to renew the
            catchError(error => EMPTY)
          )
        ),
        tap(({ token }) => {
          this.setTokenWithUser(token);
        })
      )
      .subscribe();
  }

  public decodeToken(token: string): { email: string } {
    const decoded = jwtDecode<{ email: string; exp: number }>(token);
    const now = Date.now() / 1000;

    if (decoded.exp < now) {
      throw new Error('The token has expired!');
    }

    return { email: decoded.email };
  }

  public requestPasswordResetEmail(email: string): Observable<void> {
    return this.http.post<void>('/v2/auth/password-reset/request', { email });
  }

  public resetPassword(email: string, password: string, secret: string): Observable<void> {
    return this.http.post<void>('/v2/auth/password-reset/reset', { email, password, secret });
  }

  public changePassword(password: string): Observable<void> {
    return this.http.post<void>('/v2/auth/password-reset/reset-with-token', { password });
  }

  /**
   * This function sets the token and caches it's content at the same time. This
   * function should be used to add/update the token in the auth service.
   *
   * @param token the authorization token received from auth
   */
  private setTokenWithUser(token: string) {
    localStorage.setItem(this.TOKEN_NAME, token);
    this.cachedParsedToken = jwtDecode<any>(token);
  }

  /**
   * This function clears the token and the cached parsed content at the same time. This
   * function should be used to remove the token in the auth service.
   */
  private clearTokenWithUser() {
    localStorage.removeItem(this.TOKEN_NAME);
    this.cachedParsedToken = undefined;
  }
}
