import { Injectable } from '@angular/core';
import { NetworkService } from '@spreadmonitor/network';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ContractStatus } from '../../shared/enums';
import { ApiResponse, ContractGroup, ListingQueryParameters } from '../../shared/interfaces';
import { Contract } from '../../shared/interfaces/contracts/contract.interface';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private readonly network: NetworkService) {}

  public listContractGroups(
    queryParams?: ListingQueryParameters<ContractGroup>
  ): Observable<ApiResponse<ContractGroup[]>> {
    /** Remove the undefined values from the paramter list */
    const sanitizedParameters = Object.fromEntries(
      Object.entries(queryParams).filter(([, value]) => value !== undefined && value !== null && value !== '')
    );

    return this.network.get<ApiResponse<ContractGroup[]>>('/v1/contract-groups', sanitizedParameters);
  }

  public listContracts(queryParams?: ListingQueryParameters<Contract>): Observable<ApiResponse<ContractGroup[]>> {
    /** Remove the undefined values from the paramter list */
    const sanitizedParameters = Object.fromEntries(
      Object.entries(queryParams).filter(([, value]) => value !== undefined && value !== null && value !== '')
    );

    return this.network.get<ApiResponse<ContractGroup[]>>('/v1/contracts', sanitizedParameters);
  }

  public loadContractGroupDetails(id: number): Observable<ApiResponse<ContractGroup>> {
    return this.network.get<ApiResponse<ContractGroup>>(`/v1/contract-groups/${id}`);
  }

  public loadContractDetails(id: number): Observable<ApiResponse<Contract>> {
    return this.network.get<ApiResponse<Contract>>(`/v1/contracts/${id}`);
  }

  public updateContractGroupStatus(id: number, offerStatus: ContractStatus): Observable<ContractGroup> {
    return this.network
      .patch<ApiResponse<ContractGroup>>(`/v1/contract-groups/${id}`, { status: offerStatus })
      .pipe(pluck('payload'));
  }

  public updateContractGroupDates(id: number, startDate: string, endDate: string): Observable<ContractGroup> {
    return this.network
      .patch<ApiResponse<ContractGroup>>(`/v1/contract-groups/${id}`, { startDate, endDate })
      .pipe(pluck('payload'));
  }

  public createContract(accessSlug: string): Observable<ContractGroup> {
    return this.network.post('/v1/contract-groups', { offerAccessSlug: accessSlug }).pipe(pluck('payload'));
  }

  public saveContract(id: number, contract: Partial<Contract>, force: boolean): Observable<ApiResponse<Contract>> {
    return this.network.put<ApiResponse<Contract>>(`/v1/contracts/${id}`, contract, { force });
  }

  /**
   * Moves selected contracts to a selected contract group.
   *
   * @param currentContractGroup - The id of the current contract group.
   * @param targetContractGroup - The id of the target contract group.
   * @param contracts - The ids od the selected contracts.
   */
  public moveContract(
    currentContractGroup: number,
    targetContractGroup: number,
    contracts: number[]
  ): Observable<void> {
    return this.network.post(`/v1/contract-groups/${currentContractGroup}/contract-transfer`, {
      targetContractGroup,
      contracts,
    });
  }

  /**
   * Moves selected PODs to a selected contract.
   *
   * @param podIds - IDs of the PODs which need to be moved to the target contract.
   * @param currentContract - The ID of the current contract.
   * @param targetContract - The ID of the target contract.
   */
  public transferPod(
    podIds: number[],
    currentContract: number,
    targetContract: number
  ): Observable<{ success: boolean }> {
    return this.network.post<{ success: boolean }>(`/v1/contracts/${currentContract}/pod-transfer`, {
      podIds,
      targetContract,
    });
  }

  /**
   * Uploads a single XLS/XLSX file that holds multiple contracts.
   *
   * @param file - The file that will be sent to the API.
   */
  public batchUploadContracts(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('batchContracts', file);

    return this.network.post<void>(`/v1/contract-groups/batch-upload`, formData);
  }
}
